/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes, {number} from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            envName
          }
        }
      }
    `
  )
  const envName = site.siteMetadata?.envName;

  console.log(envName);
  const script = (() => {
    if (envName === 'default') {
      return [
           {type: "text/javascript", src: "https://www.googletagmanager.com/gtag/js?id=G-VTB6NLYV0N", async: "async"},
           {innerHTML: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-VTB6NLYV0N');"},
        {innerHTML: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
                 " new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
                 " j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
                 " 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
                 " })(window,document,'script','dataLayer','GTM-KNLMT9B');"}];
    } else if(envName === 'si'){
      return [
        // {type: "text/javascript", src: "https://www.googletagmanager.com/gtag/js?id=GTM-MZQSRQM", async: "async"},
        // {innerHTML: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'GTM-MZQSRQM');"},
        {innerHTML: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
              " new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
              " j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
              " 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
              " })(window,document,'script','dataLayer','GTM-MZQSRQM');"}];
    }else{
      return null;
    }
  })();


  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      script={script}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  script: PropTypes.arrayOf(PropTypes.object),
  noscript: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
